// @flow
import * as React from "react";
import Link from "next/link";

export default function BlogCard(props) {
  return (
    <div className="col-md-6 col-lg-4">
      <article className="post">
        <Link className="stretched-link" href={`/Article/${props.pageURL}`}>
          <a className="stretched-link"></a>
        </Link>
        <figure className="feature-image">
          <img alt={props.content} src={props.img ? props.img : null} />
        </figure>
        <div className="entry-content">
          <h3>
            <span>{props.content}</span>
          </h3>
          <div className="entry-meta">
            <p className="posted-on mb-0">
              <span className="px-0">
                <i className="far fa-clock mr-2"></i>
                {`${new Date(props.date).toLocaleString("en", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}`}
              </span>
            </p>
          </div>
        </div>
      </article>
    </div>
  );
}
